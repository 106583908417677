import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import { useState } from 'react'
import router from 'next/router'

import SectionLayout from '../../commons/layouts/SectionLayout'

import AreaPrefectureList from './AreaPrefectureList'
import AreaPlaceList from './AreaPlaceList'

import styles from './LocationSection.module.scss'
import { Prefectures } from 'state/ducks/prefecture/models'

import { Prefecture } from 'state/ducks/prefecture/models'
import { City } from 'state/ducks/country/models'
interface Props {
  locations: any
  prefectures?: Prefectures
  cities?: City[]
  locale: string
  countryCode: string
  onScrollLocation: () => void
}

const LocationDisplaySection: React.FC<Props> = ({
  locations,
  prefectures,
  cities,
  locale,
  countryCode = 'jpn',
  onScrollLocation,
}: Props) => {
  const { t } = useTranslation()
  const getFieldName = (locale, item: Prefecture | City, key) => {
    return locale === 'ja' ? item[key] : item[`${locale}_${key}`]
  }

  const [selectedPrefecture, setSelectedPrefecture] = useState<Prefecture>()

  return (
    <SectionLayout sectionName="location" title={t('TOP.LOCATION.TITLE')}>
      <div
        className={`${styles.prefectures} ${!!selectedPrefecture && styles.prefecturesSelected}`}
      >
        {prefectures.map((item, index) => (
          <>
            {item.prefectures.map((prefecture, index) => (
              <div
                key={index}
                className={`${styles.selectedAreaWrap} ${
                  selectedPrefecture?.slug === prefecture.slug
                    ? styles.selectedAreaWrapSelected
                    : ''
                }`}
              >
                <div className={styles.selectedArea}>
                  <p className={styles.backList}>
                    <button
                      className={styles.btnBack}
                      onClick={() => setSelectedPrefecture(null)}
                      type="button"
                    >
                      {t('TOP.LOCATION.BACK_BUTTON_TEXT')}
                    </button>
                  </p>
                  <h3 className={styles.areaName}>
                    <Link href={`/jpn/city/${prefecture.slug}`}>
                      <a className={styles.areaNameLink}>
                        {t('TOP.LOCATION.PREFECTURE_AREA_LABEL', {
                          prefecture: prefecture.name,
                        })}
                      </a>
                    </Link>
                  </h3>
                  <AreaPlaceList
                    prefectureSlug={prefecture.slug}
                    places={prefecture.stations}
                    label={t('TOP.LOCATION.STATION_LABEL')}
                    countryCode={countryCode}
                    locale={locale}
                  />
                  <div className={styles.stationsContent}>
                    <AreaPlaceList
                      prefectureSlug={prefecture.slug}
                      places={prefecture.facilities}
                      label={t('TOP.LOCATION.FACILITY_LABEL')}
                      countryCode={countryCode}
                      locale={locale}
                    />
                  </div>
                </div>
              </div>
            ))}
          </>
        ))}
      </div>
      <div
        className={`${styles.areaItemsWrap} ${
          !!selectedPrefecture && styles.areaItemsWrapSelected
        }`}
      >
        {locations?.length > 0 && (
          <ul className={styles.locationsItems}>
            {locations.map((location, index) => (
              <li
                className={styles.item}
                style={{
                  background: location.backgroundImage,
                  backgroundSize: 'cover',
                }}
                key={index}
              >
                <div className={styles.itemOverlay}>
                  <Link href={`/jpn/city/${location.slug}`} passHref>
                    <a className={styles.areaNameLink}>{location.name}</a>
                  </Link>
                </div>
              </li>
            ))}
          </ul>
        )}
        <div className={styles.areaItems}>
          {countryCode === 'jpn' &&
            prefectures.length > 0 &&
            prefectures.map((prefecture, index) => (
              <div className={styles.areaItem} key={index}>
                <AreaPrefectureList
                  areaName={
                    locale === 'ja' ? prefecture.area_name : prefecture[`${locale}_area_name`]
                  }
                  prefectures={prefecture.prefectures}
                  onSelectAreaName={(_prefecture: Prefecture) => {
                    setSelectedPrefecture(_prefecture)
                    onScrollLocation()
                  }}
                  locale={locale}
                  countryCode={countryCode}
                />
              </div>
            ))}
          {countryCode === 'twn' && cities && (
            <>
              <div className={styles.gridLocationContainer}>
                {cities.map((item, index) => {
                  return (
                    <div className={styles.location} key={index}>
                      <p className={styles.area}>{getFieldName(locale, item, 'area_name')}</p>

                      <div className={styles.cityContainer}>
                        {(item.cities || []).map((city, index) => {
                          return (
                            <p
                              onClick={() =>
                                router.push(
                                  `${locale === 'ja' ? '/' + locale : ''}/twn/city/${city.slug}`
                                )
                              }
                              className={styles.city}
                              key={index}
                            >
                              {getFieldName(locale, city, 'name')}
                            </p>
                          )
                        })}
                      </div>
                    </div>
                  )
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </SectionLayout>
  )
}

export default LocationDisplaySection
