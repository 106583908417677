import Carousel from 'react-multi-carousel'

import { osName } from 'lib/checkDevice'

import styles from './MainBannerSection.module.scss'
interface Props {
  locale: string
}

const MainBannerSection: React.VFC<Props> = ({ locale }: Props) => {
  const getAppDownloadUrl = () => {
    if (osName === 'ios' || osName === 'mac') {
      return 'https://itunes.apple.com/jp/app/ecbo-cloak/id1443707795'
    }
    return 'https://play.google.com/store/apps/details?id=io.ecbo.cloak'
  }

  const appDownloadUrl = getAppDownloadUrl()

  /**
   * @TODO
   * 将来的にAdminから取得するように対応
   */
  const banners = [
    {
      name: {
        en: 'Leave your luggage with us and get crispy and comfortable! Download the app first!',
        ja: '荷物を預けてサクサク快適！まずはアプリをダウンロード',
      },
      image_pc: '/banner_ecbo_app_pc.webp',
      image_sp: '/banner_ecbo_app_sp.webp',
      url: appDownloadUrl,
    },
    {
      name: {
        en: 'HOW TO - Click here to learn how to use ecbo cloak.',
        ja: 'HOW TO - ecbo cloakの使い方はこちら',
      },
      image_pc: '/banner_ecbo_beginner_pc.webp',
      image_sp: '/banner_ecbo_beginner_sp.webp',
      url: 'https://ecbonist.ecbo.io/how_to_use_ecbo_cloak/',
    },
    {
      name: {
        en: 'TOKYO MARINE NICHIDO Tokio Marine Nichido in cooperation with ecbo clock - ecbo clock loss insurance covers up to 200,000 yen (*Some deductibles apply)',
        ja: 'TOKYO MARINE NICHIDO 東京海上日動とecbo clockの提携 - ecbo clockの紛失保険なら最大20万円まで保証(※一部免責金額等があります)',
      },
      image_pc: '/banner_ecbo_insurance_pc.webp',
      image_sp: '/banner_ecbo_insurance_sp.webp',
      url: 'https://intercom.help/ecbocloak/ja/articles/9333901',
    },
  ]

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 992 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 3000, min: 576 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 575, min: 0 },
      items: 1,
    },
  }

  const CustomDot: any = ({ onMove, index, onClick, active }) => {
    return (
      <li
        className={`${styles.mainBannersDotsItem} ${
          active ? styles.mainBannersDotsItemActive : ''
        }`}
        onClick={() => onClick()}
      />
    )
  }

  return (
    <section className={styles.mainBannerSection}>
      <Carousel
        responsive={responsive}
        autoPlay={true}
        infinite
        swipeable
        className={styles.mainBanners}
        arrows={false}
        showDots={true}
        autoPlaySpeed={3000}
        dotListClass={styles.mainBannersDots}
        customDot={<CustomDot />}
      >
        {banners.map((banner, index: number) => (
          <a
            className={styles.mainBannersDotsItemLink}
            key={index}
            href={banner.url}
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={styles.mainBannersDotsItemLinkImgPc}
              src={banner.image_pc}
              alt={banner.name[locale]}
            />
            <img
              className={styles.mainBannersDotsItemLinkImgSp}
              src={banner.image_sp}
              alt={banner.name[locale]}
            />
          </a>
        ))}
      </Carousel>
    </section>
  )
}

export default MainBannerSection
